import Model, { attr, belongsTo } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing locations' unavailable appointment slots.
 */
export default class SanitizedAppointment extends Model {
    /**
     * Datetime of unavailable appointment slot.
     *
     * @type {Date}
     */
    @attr('date')
    dateTimeUtc;

    /**
     * Whether the appointment is a Tour Now.
     */
    @attr('boolean')
    isTourNow;

    /**
     * Location reference of unavailable appointment slot.
     *
     * @type {models.Location}
     */
    @belongsTo('location', { inverse: 'unavailablePeriods' })
    location;
}
