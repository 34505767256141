import Model, { attr } from '@ember-data/model';
import { get } from '@ember/object';

/**
 * @classdesc
 * Model for loading dealer-specific branding and styling.
 */
export default class ContextModel extends Model {
    /**
     * Color of application body.
     *
     * @type {String}
     */
    @attr('string')
    backgroundColor;

    /**
     * URL of dealer logo.
     *
     * @type {String}
     */
    @attr('string')
    logoUrl;

    /**
     * Length of Self-Guided Tours appointments. Default is 90 minutes.
     *
     * @type {Number}
     * @default 90
     */
    @attr('number', { defaultValue: 90 })
    appointmentLength;

    /**
     * System Group ID for context.
     *
     * @type {number}
     */
    @attr('number')
    systemGroupId;

    /**
     * Does the dealer require credit card verification
     *
     * @type {boolean}
     */
    @attr('boolean')
    showCCPage;

    /**
     * Is the dealer a PointCentral dealer?
     *
     * @type {boolean}
     */
    @attr('boolean')
    hasPointCentralDealer;

    /**
     * What name should we show as powering the website?
     *
     * @type {String}
     */
    @attr('string')
    poweredByName;

    /**
     * Whether there is a community site map.
     *
     * @type {boolean}
     */
    @attr('boolean')
    hasCommunitySiteMap;

    /**
     * Whether there are terms and conditions a prospect must accept in order to book a tour.
     *
     * @type {boolean}
     */
    @attr('boolean')
    hasTermsAndConditions;

    /**
     * Cache for community site map object urls for different groups.
     * The Appointments page can have upcoming apppointments for different groups with different maps.
     *
     * @type {Object.<Number, String>}
     */
    @attr()
    communitySiteMapObjectUrls;

    /**
     * Object url for the terms and conditions file.
     *
     * @type {String}
     */
    @attr('string')
    termsAndConditionsObjectUrl;

    /**
     * Schedule for available tour times.
     *
     * @type {object}
     */
    @attr('hours-of-operation')
    hoursOfOperationSchedule;

    /**
     * Whether the group context can use Self-Guided Tours 3.0.
     *
     * @type {boolean}
     */
    @attr('boolean')
    useSGT3;

    toJSON() {
        const json = {};
        this.eachAttribute((a) => (json[a] = get(this, a)));
        return json;
    }
}
