import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

/**
 * @classdesc
 * Model for user appointment data.
 */
export default class Appointment extends Model {
    /**
     * Date appointment was created.
     *
     * @type {Date}
     */
    @attr('date')
    createdAt;

    /**
     * Date appointment was updated.
     *
     * @type {Date}
     */
    @attr('date')
    updatedAt;

    /**
     * Appointment lock code.
     *
     * @type {String}
     */
    @attr('string')
    userCode;

    /**
     * Code for onsite non-ADC integrated gate.
     *
     * @type {String}
     */
    @attr('string')
    gateCode;

    /**
     * Appointment date and time. The dateTimeUtc passed from the backend should be in UTC.
     * Note: This time will be converted to the browser's local time.
     *
     * @type {Date}
     */
    @attr('date')
    dateTimeUtc;

    /**
     * Appointment date and time in system's local timezone as a string for displaying.
     * The `dateTimeLocal` property creates a date time object from this string, so use
     * that object when displaying the local date time of an appointment for the end-user.
     *
     * @type {String}
     */
    @attr('string')
    localAppointmentStartTime;

    /**
     * Whether the appointment is an upcoming appointment.
     *
     * @type {Boolean}
     */
    @attr('boolean')
    activePendingIdVerification;

    /**
     * Appointment location.
     *
     * @type {model.Location}
     */
    @belongsTo('location', { inverse: null })
    location;

    /**
     * Appointment's dealer.
     *
     * @type {model.Context}
     */
    @belongsTo('context', { inverse: null })
    context;

    /**
     * Whether the appointment is a current appointment (i.e. not expired).
     *
     * @type {Boolean}
     */
    @attr('boolean')
    current;

    /**
     * Whether the appointment is a past appointment (i.e. completed successfully).
     *
     * @type {Boolean}
     */
    @attr('boolean')
    past;

    /**
     * Returns street address of associated location.
     *
     * @type {String}
     */
    @alias('location.streetAddress')
    streetAddress;

    /**
     * Returns region of associated location.
     *
     * @type {String}
     */
    @alias('location.region')
    region;

    /**
     * Returns full address of associated location.
     *
     * @type {String}
     */
    @alias('location.fullAddress')
    fullAddress;

    /**
     * Appointment subdomain.
     *
     * @type {String}
     */
    @attr('string')
    subdomain;

    /**
     * Whether the check-in button should be displayed.
     *
     * @type {Boolean}
     */
    @attr('boolean')
    showCheckIn;

    /**
     * Returns the timezone of the appointment.
     *
     * @type {String}
     */
    @computed('location.timeZone')
    get timeZone() {
        return this.location.timeZone;
    }

    /**
     * Local appointment date and time.
     *
     * @type {Date}
     */
    @computed('localAppointmentStartTime')
    get dateTimeLocal() {
        return new Date(this.localAppointmentStartTime);
    }

    /**
     * Appointment length.
     */
    @attr('number')
    length;
}
