import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { bool } from '@ember/object/computed';

/**
 * @classdesc
 * Base model for common data in models.Location.
 */
export default class BaseLocation extends Model {
    /**
     * Street address line 1 for Self-Guided Tours location.
     *
     * @type {String}
     */
    @attr('string')
    street1;

    /**
     * Street address line 2 for Self-Guided Tours location.
     *
     * @type {String}
     */
    @attr('string')
    street2;

    /**
     * City for Self-Guided Tours location address.
     *
     * @type {String}
     */
    @attr('string')
    city;

    /**
     * State for Self-Guided Tours location address.
     *
     * @type {String}
     */
    @attr('string')
    state;

    /**
     * Zip code for Self-Guided Tours location address.
     *
     * @type {String}
     */
    @attr('string')
    zipCode;

    /**
     * URL of picture for Self-Guided Tours location.
     *
     * @type {String}
     */
    @attr('string')
    pictureUrl;

    /**
     * Current user's appointment slot.
     *
     * @type {model.Appointment}
     */
    @belongsTo('appointment', { inverse: null })
    nextAppointment;

    /**
     * Time zone of Self-Guided Tours location.
     *
     * @type {String}
     */
    @attr('string')
    timeZone;

    /**
     * Abbreviation of the time zone of Self-Guided Tours location.
     *
     * @type {String}
     */
    @attr('string')
    timeZoneAbbreviation;

    /**
     * Returns the appointment ID if the user has an appointment scheduled at this location.
     *
     * @function
     * @returns {Boolean}
     */
    @bool('nextAppointment.id')
    hasAppointment;

    /**
     * If the unit requires photo verification in order to tour
     *
     * @type {boolean}
     */
    @attr('boolean')
    checkpointIdEnabled;

    /**
     * Whether this location has geofencing enabled.
     *
     * @type {Boolean}
     */
    @attr('boolean')
    geofenceEnabled;

    /**
     * If the user needs to be verified
     *
     * @type {boolean}
     */
    @attr('boolean')
    reverificationRequired;

    /**
     * UserId
     *
     * @type {string}
     */
    @attr('string')
    userId;

    /**
     * GroupId
     *
     * @type {string}
     */
    @attr('string')
    groupId;

    /**
     * Location ID for CheckpointID
     *
     * @type {string}
     */
    @attr('string')
    checkpointIDLocationID;

    /**
     * API Key for CheckpointID
     *
     * @type {string}
     */
    @attr('string')
    checkpointIDApiKey;

    /**
     * Returns the street address of the location.
     *
     * @type {String}
     */
    @computed('street1', 'street2')
    get streetAddress() {
        if (!this.street2) {
            return this.street1;
        }
        return this.street1 + ', ' + this.street2;
    }

    /**
     * Returns the region portion of the location.
     *
     * @type {String}
     */
    @computed('city', 'state', 'zipCode')
    get region() {
        return this.city + ', ' + this.state + ' ' + this.zipCode;
    }

    /**
     * Returns the full address of the location.
     *
     * @type {String}
     */
    @computed('streetAddress', 'region')
    get fullAddress() {
        return this.streetAddress + ', ' + this.region;
    }
}
