import Service from '@ember/service';
import { setProperties } from '@ember/object';
import { inject as service } from '@ember/service';

export default class LoginPendingBookingService extends Service {
    @service router;
    @service ajax;
    @service checkIn;

    /**
     * Flag to determine whether user tried to book appointment before
     * logging in or signing up.
     *
     * @type {Boolean}
     */
    hasPendingAppointments = false;

    /**
     * Flag to determine whether to load scheduling page before saving
     * pending appointment.
     *
     * @type {Boolean}
     */
    enableTemporaryLoading = false;

    /**
     * Appointment object to be booked upon logging in or signing up.
     *
     * @type {Appointment}
     */
    appointment = null;

    /**
     * Location that appointment is booked at.
     *
     * @type {Location}
     */
    property = null;

    loadAppointment(appointment, property) {
        setProperties(this, {
            hasPendingAppointments: true,
            appointment: appointment,
            property: property
        });
    }

    resetAppointmentData() {
        setProperties(this, {
            hasPendingAppointments: false,
            enableTemporaryLoading: false,
            appointment: null,
            property: null
        });
    }

    transitionRoute() {
        this.enableTemporaryLoading = true;
        const propertyId = this.property.id;
        const baseRoute = 'context.location';
        this.router.transitionTo(baseRoute, propertyId);
    }

    async processPendingAppointments() {
        this.transitionRoute();

        try {
            // Save the appointment.
            await this.appointment.save();
            await this.appointment.location.reload();
            this.property.nextAppointment = this.appointment;

            const tourWindowStart = new Date(
                this.appointment.dateTimeUtc.getTime() - 5 * 60000
            );
            const withinTourWindow =
                tourWindowStart <= this.appointment.createdAt;

            // Send the CPID verification if user needs to be verified and it is not within tour time.
            if (this.property.reverificationRequired && !withinTourWindow) {
                await this.checkIn.sendCheckpointIdVerification(
                    this.appointment.id,
                    false
                );
            }

            // Reset stored appointment data.
            this.resetAppointmentData();
        } catch (response) {
            this.resetAppointmentData();
            return this.ajax.handleAjaxError(
                response,
                'Unable to request Self-Guided Tours appointment. Please try again later.'
            );
        }
    }
}
