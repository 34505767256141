import Controller from '@ember/controller';
import ENV from 'unattended-showing/config/environment';
import { htmlSafe } from '@ember/template';

/**
 * The main application controller. Responsible for loading dealer-specific styling.
 */
export default class ContextController extends Controller {
    /**
     * The root URL path for all SVG sprites.
     *
     * @type {String}
     */
    get svgSpriteRoot() {
        return htmlSafe(`${ENV.rootURL}assets/svg-sprites`);
    }
}
