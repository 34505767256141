import { computed } from '@ember/object';
import { attr, hasMany } from '@ember-data/model';
import BaseLocation from './base-location';

/**
 * @classdesc
 * Model for storing Self-Guided Tours property information.
 */
export default class LocationModel extends BaseLocation {
    /**
     * Number of bedrooms for Self-Guided Tours property.
     *
     * @type {Number}
     */
    @attr('number')
    numberOfBedrooms;

    /**
     * Number of bathrooms for Self-Guided Tours property.
     *
     * @type {Number}
     */
    @attr('number')
    numberOfBathrooms;

    /**
     * Square footage of Self-Guided Tours property.
     *
     * @type {Number}
     */
    @attr('number')
    squareFootage;

    /**
     * Rent for Self-Guided Tours property.
     *
     * @type {Number}
     */
    @attr('number')
    rent;

    /**
     * Localized string for rent or price
     *
     * @type {String}
     */
    @attr('string')
    rentOrPriceString;

    /**
     * Unit name
     *
     * @type {String}
     */
    @attr('string')
    unitDescription;

    /**
     * Creation date for Self-Guided Tours property record.
     *
     * @type {Date}
     */
    @attr('date')
    createdAt;

    /**
     * Last updated date for Self-Guided Tours property record.
     *
     * @type {Date}
     */
    @attr('date')
    updatedAt;

    /**
     * Appointment slots for property that are unavailable.
     *
     * @type {model.SanitizedAppointment[]}
     */
    @hasMany('sanitized-appointment', {
        inverse: 'location'
    })
    unavailablePeriods;

    /**
     * Appointment slots retrieved from the CRM integration if the property is integrated.
     */
    @attr()
    appointmentTimeSlotsFromCrmLocal;

    /**
     * Whether the location's community has a site map.
     *
     * @type {boolean}
     */
    @attr('boolean')
    hasCommunitySiteMap;

    /**
     * Returns property details along with its label.
     *
     * @type {{title: string, value: string}[]}
     */
    @computed(
        'formattedRent',
        'numberOfBathrooms',
        'numberOfBedrooms',
        'rent',
        'rentOrPriceString',
        'squareFootage'
    )
    get availableDetails() {
        return [
            ['Beds', this.numberOfBedrooms.toLocaleString()],
            ['Baths', this.numberOfBathrooms.toLocaleString()],
            ['Sq. Ft.', this.squareFootage.toLocaleString()],
            [this.rentOrPriceString, this.formattedRent]
        ]
            .map(([title, value]) => ({ title, value }))
            .filter((attribute) => attribute.value);
    }

    /**
     * Returns the locations rent as a formatted string.
     *
     * @type {string}
     */
    @computed('rent')
    get formattedRent() {
        // We expect rent to be a number, but just in case, parse it.
        const rent = parseInt(this.rent);
        return !rent ? '-' : `$${rent.toLocaleString()}`;
    }
}
