import Model, { belongsTo } from '@ember-data/model';

/**
 * @classdesc
 * Model for storing new user's signup information.
 */
export default class SignUpModel extends Model {
    /**
     * New user's profile
     *
     * @type {Profile}
     */
    @belongsTo('profile')
    profile;

    /**
     * New user's password.
     *
     * @type {ProfilePassword}
     */
    @belongsTo('profile-password', { inverse: null })
    password;
}
